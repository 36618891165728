import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"

import CalloutIcon from "../../images/icons/icon-callout-ida-connect.png"
import akbcares from "../../images/AkebiaCares-logo.png"

import HeroVideo from "../../components/shared/generic/hero-video"
import HeroVideoSrcMp4 from "../../video/Aury_walk_sequence_IDA_1088x580_v006.mp4"

// carousel thumbs
import SwipeableMobileStepper from "../../components/shared/mobile-carousel"
import slide1 from "../../images/heroes/characters_01_Aury_HP0.png"
import slide2 from "../../images/heroes/characters_05_NonIndyKate.jpg"
import slide3 from "../../images/heroes/characters_06_ShotzyPierce0.png"
import slide4 from "../../images/heroes/characters_07_Ivy_Iron0.png"
import slide5 from "../../images/heroes/characters_08_OBertha0.png"

const IndexPage = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc. 2021.
            </>,
            <>
                Fishbane S, Block Ga, Loram L, et al. Effects of ferric citrate
                in patients with nondialysis-dependent CKD and iron deficiency
                anemia. <em>J&nbsp;Am Soc Nephrol</em>. 2017;28(6):1851-1858.
            </>,
            <>Data on File 16, Akebia Therapeutics, Inc.</>,
            <>Data on File 14, Akebia Therapeutics, Inc.</>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/"
            />
            <body className="ida" id="ida-hcp-home" />
        </Helmet>

        <Seo
            title="Home"
            description="Official AURYXIA website for US Healthcare Professionals. Find all the information you need about FDA-approved AURYXIA. See Prescribing Information and Full Safety Information."
        />
        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <HeroVideo srcMp4={HeroVideoSrcMp4} />
                </div>

                <div>
                    <h1>
                        Need a different choice for IDA in{" "}
                        <span className="nowrap">CKD-NDD?</span>{" "}
                        <br className="small-only" />
                        Reach for AURYXIA.
                    </h1>
                    <p className="footnote">
                        <strong>
                            CKD=chronic kidney disease; IDA=iron deficiency
                            anemia; NDD=non-dialysis dependent.
                        </strong>{" "}
                        <span className="large-only">
                            *Not specifically indicated.
                        </span>
                    </p>
                </div>
            </div>
        </section>

        <section id="description" className="flood-ida col-8">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="100"
                data-sal-easing="ease-out-expo"
            >
                <div>
                    <div>
                        <h2 className="ida">
                            AURYXIA is the first oral iron specifically
                            indicated for adults with CKD not on dialysis
                            <sup>1</sup>
                        </h2>
                        <p className="center">
                            AURYXIA has been proven to increase Hb and iron
                            parameters (TSAT and ferritin) with a starting dose
                            of 1 tablet 3 times a day.
                            <sup>1-4&#x2020;&#x2021;</sup> So your clinical
                            interests&mdash;and your patients&rsquo;
                            interests&mdash;line up.
                        </p>
                        <p>In the clinical trial:</p>
                        <ul>
                            <li>
                                Mean Hb increased from 10.4 g/dL to 11.4 g/dL by
                                the end of the 16-week period<sup>2,3</sup>
                            </li>
                            <li>
                                Mean TSAT increased from 20.2% at baseline to
                                35.6% at Week 16<sup>4</sup>
                            </li>
                            <li>
                                Patients on AURYXIA also achieved a mean
                                increase in serum ferritin of 163 ng/mL from
                                baseline (85.9 ng/mL) at Week 16<sup>1,2</sup>
                            </li>
                        </ul>
                        <div className="flex-row links">
                            <div class="mr_none">
                                <Link
                                    to="/iron-deficiency-anemia/efficacy/"
                                    className="cta ida"
                                >
                                    <span>EXPLORE EFFICACY</span>
                                </Link>
                            </div>
                            <div class="mr_none">
                                <Link
                                    to="/iron-deficiency-anemia/dosing/"
                                    className="cta ida"
                                >
                                    <span>GET DOSING INFORMATION</span>
                                </Link>
                            </div>
                        </div>

                        <p className="center">
                            <Link
                                to="/iron-deficiency-anemia/efficacy/#trial-design"
                                className="gradient-link blk"
                            >
                                SEE TRIAL DESIGN
                            </Link>
                        </p>

                        <p className="footnote">
                            Hb=hemoglobin; TSAT=transferrin saturation.
                            <br />
                            <sup className="sup_margin">&#x2020;</sup>IV iron
                            use and ESA use were not allowed per trial design
                            and exclusion criteria.<sup>1</sup>
                            <br />
                            <sup className="sup_margin">&#x2021;</sup>In the
                            clinical trial, patients required an average of 5
                            tablets per day to increase hemoglobin levels.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section id="mobile-carousel" className="small-only center">
            <div className="flex-row">
                <div>
                    <SwipeableMobileStepper
                        stepperImgs={[
                            {
                                label: "photo 1",
                                imgPath: slide1,
                            },
                            {
                                label: "photo 2",
                                imgPath: slide2,
                            },
                            {
                                label: "photo 3",
                                imgPath: slide3,
                            },
                            {
                                label: "photo 4",
                                imgPath: slide4,
                            },
                            {
                                label: "photo 5",
                                imgPath: slide5,
                            },
                        ]}
                    />
                </div>
            </div>

            <p className="flex-row small-only">*Not specifically indicated.</p>
        </section>
        <div className="single_card">
            <EopCalloutCards
                siteAudience="ida"
                callOneClass={"akebia-cares"}
                callOneLink={"https://www.akebiacareshcp.com/"}
                callOneContent={
                    <>
                        <img src={akbcares} alt="AkebiaCares" height="81" />
                        <h3>
                            Your partner in helping patients access the
                            medication they need
                        </h3>
                        <p>
                            AkebiaCares supports your patients with information
                            and resources that can help people start and stay on
                            AURYXIA.
                        </p>
                        <button className="cta akb">
                            <span>FIND SUPPORT OPTIONS</span>
                        </button>
                    </>
                }
                // callTwoClass="highlight"
                // callTwoLink={"/iron-deficiency-anemia/sign-up?request-rep=true"}
                // //callTwoLink={"/"}
                // callTwoContent={
                //   <>
                //     <img src={CalloutIcon} alt="" width="98" height="98" />
                //     <h3>Stay connected with&nbsp;AURYXIA</h3>
                //     <p>Whether you want to talk to a sales representative or order samples, we have you covered. Let us know what you&#8217;re interested in and we&#8217;ll be in touch.</p>
                //     <button className="cta ida"><span>GET STARTED</span></button>
                //   </>
                // }
            />
        </div>
        <section id="crosslink-slab" className="hpp">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="100"
                data-sal-easing="ease-out-expo"
            >
                <div>
                    <h3>
                        AURYXIA is also indicated to treat hyperphosphatemia
                        (HP) in adult patients with CKD on dialysis.<sup>1</sup>
                    </h3>
                    <Link to="/hyperphosphatemia" className="cta hpp">
                        <span>EXPLORE MORE</span>
                    </Link>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default IndexPage
